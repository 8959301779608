import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Consult from "../components/Consult"
import { useLandingPageData } from "../data/useLandingPageData"
import { graphql } from "gatsby"
import Breadcrumb from "../components/Breadcrumb"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import Sidebar from "../components/FindCoursePage/Sidebar"
import CourseItem from "../components/FindCoursePage/CourseItem"
import OnlineCourseCard from "../components/Card/OnlineCourseCard"
import TagItem from "../components/Tag/TagItem"
import { GatsbyImage } from "gatsby-plugin-image"
import TeacherCard from "../components/Card/TeacherCard"
import Navigation from "../components/Navigation"
import { useMetadata } from "../data/use-metadata"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { cardsExceptSelf } from "../functions/cardsExceptSelf"
import TagToFilterPage from "../components/Tag/TagToFilterPage"

const CourseBlock = ({ data }) => {
  const { level, minute, episode } = data

  const titleClass =
    "mb-2 !leading-normal text-center font-bold text-amber-400 sm:text-lg"

  const levelList = level.map((item, idx) => {
    const isLast = level.length - 1 === idx

    return <TagItem key={item.id} data={item} isLast={isLast} />
  })

  return (
    <div className="p-8 bg-neutral-50">
      <ul className="divide-y grid grid-cols-1 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
        <li className="flex flex-col items-center pb-6 max-w- sm:pb-0">
          <p className={titleClass}>適合對象</p>
          <div>{levelList}</div>
        </li>
        <li className="flex flex-col items-center py-6 sm:py-0">
          <p className={titleClass}>集數</p>
          <span className='className="!leading-normal text-neutral-500 sm:text-lg"'>
            {episode}集
          </span>
        </li>
        <li className="flex flex-col items-center pt-6 sm:pt-0">
          <p className={titleClass}>總時長</p>
          <span className="!leading-normal text-neutral-500 sm:text-lg">
            {minute}分鐘
          </span>
        </li>
      </ul>
    </div>
  )
}

const OnlineCourseTemplate = ({ data }) => {
  const { consultGatsbyImageData, consultTitle } = useLandingPageData()

  const {
    contentfulOnlineCourse: course,
    popularCourses,
    recommendedCourses,
    courses,
  } = data

  const {
    image: {
      gatsbyImageData,
      file: { url },
    },
    description: { description },
    topic,
    level,
  } = course

  const {
    name,
    type,
    description: { description: authorDescription },
    slug: teacherSlug,
    image: { gatsbyImageData: authorGatsbyImageData },
  } = course.author

  const slug = "online-course"

  const crumbs = [
    {
      name: "首頁",
      url: "/",
    },
    {
      name: "找線上課",
      url: `/${slug}`,
    },
    {
      name: `${course.title}`,
      url: `/${slug}/${course.slug}`,
    },
  ]

  const { siteUrl } = useMetadata()
  const canonical = `${siteUrl}/${slug}/${course.slug}`

  const tags = []

  topic.forEach(i => tags.push(i))
  level.forEach(i => tags.push(i))

  const tagList = tags.map((tag, idx) => {
    const pathName = `/${slug}?type=${tag.sys.contentType.sys.id}&tag=${tag.name}`
    const tagStyle = idx === tags.length - 1 ? "mr-0" : "mr-3.5"

    return (
      <TagToFilterPage
        tagStyle={tagStyle}
        key={`key-${idx}`}
        slug={pathName}
        name={tag.name}
      />
    )
  })

  // 精選區塊
  const recommendedCoursesItems = recommendedCourses.nodes.map(course => (
    <CourseItem key={course.id} course={course} isOnlineCourse={true} />
  ))

  // 熱門區塊
  const popularCoursesItems = popularCourses.nodes.map(course => (
    <CourseItem key={course.id} course={course} isOnlineCourse={true} />
  ))

  const onlineCourseCardsExceptSelf = cardsExceptSelf(courses, course)

  const onlineCourses = onlineCourseCardsExceptSelf.map(
    (
      { id, title, topic, level, slug, minute, image: { gatsbyImageData } },
      idx
    ) => {
      // 猜你會喜歡卡片顯示邏輯 > 手機三張 平板四張 電腦版三張
      const isLast =
        onlineCourseCardsExceptSelf.length > 3 &&
        idx === onlineCourseCardsExceptSelf.length - 1

      return (
        <OnlineCourseCard
          key={id}
          title={title}
          topic={topic}
          level={level}
          slug={slug}
          minute={minute}
          image={gatsbyImageData}
          isLast={isLast}
        />
      )
    }
  )

  return (
    <Layout>
      <GatsbySeo
        title={course.title}
        description={description}
        canonical={canonical}
        openGraph={{
          url: canonical,
          title: course.title,
          images: [
            {
              url: `https:${url}`,
              width: 1200,
              height: 630,
              alt: course.title,
            },
          ],
        }}
      />

      <div className="my-12 wrapper">
        <Breadcrumb crumbs={crumbs} />
      </div>

      <div className="wrapper">
        <div className="lg:grid lg:grid-cols-5 lg:gap-8">
          <div className="lg:col-span-4 lg:h-fit">
            <h1 className="text-3xl tracking-wide font-bold text-neutral-800 sm:text-5xl">
              {course.title}
            </h1>
            <div className="my-6">{tagList}</div>
            <GatsbyImage
              image={gatsbyImageData}
              alt="bg image"
              className="w-full mb-6"
            />
            <p className="!leading-normal text-neutral-600 mb-6 sm:text-lg">
              {description}
            </p>
            <CourseBlock data={course} />
            {/* 卡片 markdown 講師觀點 */}
            <div className="markdown inner-page lg:col-span-4">
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {course?.content?.content}
              </ReactMarkdown>
            </div>
            <h2 className="mt-12 mb-6 text-2xl !leading-loose !tracking-wide font-bold text-neutral-800 sm:text-3xl">
              講師介紹
            </h2>
            <div className="grid grid-cols-1">
              <TeacherCard
                slug={teacherSlug}
                name={name}
                gatsbyImageData={authorGatsbyImageData}
                type={type}
                description={authorDescription}
                isPostPage={true}
              />
            </div>
          </div>
          <Sidebar
            popularItems={popularCoursesItems}
            recommendedItems={recommendedCoursesItems}
          />
        </div>
      </div>

      <div className="wrapper my-24 lg:my-36">
        {onlineCourseCardsExceptSelf.length ? (
          <div className="border-t-4 border-amber-400">
            <div className="flex justify-between items-center mb-8 mt-3.5 sm:mb-12">
              <h2 className="text-3xl !leading-normal tracking-wide font-bold text-neutral-800 lg:text-5xl">
                猜你會喜歡
              </h2>
              <Navigation slug={slug} text="全部線上課" />
            </div>
            <ul className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {onlineCourses}
            </ul>
          </div>
        ) : null}
      </div>

      <Consult
        gatsbyImageData={consultGatsbyImageData}
        consultTitle={consultTitle}
      />
    </Layout>
  )
}

CourseBlock.propTypes = {
  data: PropTypes.object,
}

OnlineCourseTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default OnlineCourseTemplate

export const pageQuery = graphql`
  query ($slug: String!, $firstTopic: String!) {
    contentfulOnlineCourse(slug: { eq: $slug }) {
      id
      slug
      image {
        gatsbyImageData(
          width: 1170
          height: 658
          placeholder: BLURRED
          quality: 100
        )
        file {
          url
        }
      }
      title
      episode
      minute
      description {
        description
      }
      topic {
        id
        name
        contentful_id
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      level {
        id
        name
        contentful_id
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      content {
        content
      }
      author {
        name
        type
        slug
        description {
          description
        }
        image {
          gatsbyImageData
          file {
            url
          }
        }
      }
    }
    recommendedCourses: allContentfulOnlineCourse(
      limit: 3
      sort: { fields: createdAt, order: DESC }
      filter: { isRecommended: { eq: true } }
    ) {
      nodes {
        id
        title
        minute
        slug
      }
    }
    popularCourses: allContentfulOnlineCourse(
      limit: 3
      sort: { fields: createdAt, order: DESC }
      filter: { isPopular: { eq: true } }
    ) {
      nodes {
        id
        title
        minute
        slug
      }
    }
    courses: allContentfulOnlineCourse(
      filter: { topic: { elemMatch: { name: { eq: $firstTopic } } } }
      sort: { fields: updatedAt, order: DESC }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        minute
        image {
          gatsbyImageData(
            width: 468
            height: 466
            placeholder: BLURRED
            quality: 100
          )
        }
        topic {
          id
          name
        }
        level {
          id
          name
        }
      }
    }
  }
`
