import React from "react"
import PropTypes from "prop-types"
import Link from "../Link"
import { GatsbyImage } from "gatsby-plugin-image"

const TeacherCard = ({
  slug = "",
  name,
  type,
  image,
  description,
  gatsbyImageData,
  isLast = false,
  isTeacherPage = false,
  isPostPage = false,
  isCreateNewPage = false,
  isLastItem,
}) => {
  return slug ? (
    <Link
      to={`/teacher/${slug}`}
      as={isCreateNewPage ? "a" : "link"}
      style={`border border-neutral-400 w-full p-5 ease-in-out duration-300 hover:border-neutral-600 sm:p-6 ${
        isLast ? "hidden sm:block lg:hidden" : ""
      } ${isLastItem ? "hidden lg:block" : ""}`}
    >
      <div className="flex gap-6 lg:gap-8">
        {gatsbyImageData && (
          <GatsbyImage
            image={gatsbyImageData}
            alt={`${name} - 言果學習講師個人形象照`}
            className={`flex-shrink-0 -z-10 h-24 w-24 object-cover rounded-full lg:my-auto ${
              isTeacherPage ? "lg:h-28 lg:w-28" : "lg:h-36 lg:w-36"
            }`}
          />
        )}
        {image && (
          <img
            src={image}
            alt={name}
            className="h-24 object-cover rounded-full lg:h-36 lg:my-auto"
          />
        )}
        <div className="flex flex-col justify-center">
          <div className={isPostPage ? "lg:flex lg:items-center lg:gap-4" : ""}>
            <p className="text-2xl !leading-normal font-bold text-neutral-800 pb-1 whitespace-nowrap">
              {name}
            </p>
            <p className="text-lg leading-loose text-neutral-600 lg:text-xl">
              {type}
            </p>
          </div>
          <div className="hidden lg:block">
            <div className="w-full my-4 border border-neutral-300 lg:my-3" />
            <p className="leading-normal text-neutral-600 line-clamp-3">
              {description}
            </p>
          </div>
        </div>
      </div>
      <div className="lg:hidden">
        <div className="w-full my-4 border border-neutral-300" />
        <p className="leading-normal text-neutral-600 line-clamp-3">
          {description}
        </p>
      </div>
    </Link>
  ) : (
    <div className="cursor-not-allowed border border-neutral-400 w-full p-5 ease-in-out duration-300 hover:border-neutral-600 sm:p-6">
      <div className="flex gap-6 lg:gap-8">
        {gatsbyImageData && (
          <GatsbyImage
            image={gatsbyImageData}
            alt={name}
            className="flex-shrink-0 -z-10 h-24 w-24 object-cover rounded-full lg:h-36 lg:w-36 lg:my-auto"
          />
        )}
        {image && (
          <img
            src={image}
            alt={name}
            className="h-24 object-cover rounded-full lg:h-36 lg:my-auto"
          />
        )}
        <div className="flex flex-col justify-center">
          <div>
            <p className="text-2xl !leading-normal font-bold text-neutral-800 pb-1 whitespace-nowrap">
              {name}
            </p>
            <p className="text-lg leading-loose text-neutral-600 lg:text-xl">
              {type}
            </p>
          </div>
          <div className="hidden lg:block">
            <div className="w-full my-4 border border-neutral-300 lg:my-3" />
            <p className="leading-normal text-neutral-600 line-clamp-3">
              {description}
            </p>
          </div>
        </div>
      </div>
      <div className="lg:hidden">
        <div className="w-full my-4 border border-neutral-300" />
        <p className="leading-normal text-neutral-600 line-clamp-3">
          {description}
        </p>
      </div>
    </div>
  )
}

TeacherCard.propTypes = {
  slug: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  gatsbyImageData: PropTypes.any,
  isLast: PropTypes.bool,
  isTeacherPage: PropTypes.bool,
  isPostPage: PropTypes.bool,
  isCreateNewPage: PropTypes.bool,
  isLastItem: PropTypes.bool,
}

export default TeacherCard
