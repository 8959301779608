import React from "react"
import PropTypes from "prop-types"
import Link from "../Link"
import { ChevronRightIcon, ClockIcon } from "@heroicons/react/solid"
import { GatsbyImage } from "gatsby-plugin-image"

const Tag = ({ children, isLastItem }) => (
  <p
    className={`text-sm text-neutral-600 rounded bg-neutral-200 px-3 py-2 my-2 inline-block ${
      isLastItem ? "mr-0" : "mr-3.5"
    }`}
  >
    {children}
  </p>
)

const OnlineCourseCard = ({
  title,
  url,
  topic,
  level,
  slug,
  minute,
  image,
  isLast = false,
  isCreateNewPage = false,
  isLastItem,
}) => {
  const tags = [topic[0], level[0]]

  const tagList = tags.map((tag, idx) => (
    <Tag isLastItem={idx === tags.length - 1} key={`key-${idx}`}>
      {tag.name}
    </Tag>
  ))

  return (
    <Link
      to={`/online-course/${slug}`}
      as={isCreateNewPage ? "a" : "link"}
      style={`border border-neutral-400 border-b-4 ease-in-out duration-300 hover:border-b-neutral-500 ${
        isLast ? "hidden sm:block lg:hidden" : ""
      } ${isLastItem ? "hidden lg:block" : ""}`}
    >
      {url ? (
        <img
          src={url}
          alt={title}
          className="block w-full aspect-video object-cover"
        />
      ) : (
        <GatsbyImage
          image={image}
          alt={title}
          className="block w-full aspect-video object-cover"
        />
      )}
      <div className="px-3 pt-2 pb-3.5 sm:px-6 sm:pb-6 sm:pt-4">
        {tagList}
        <p className="text-2xl !leading-normal font-bold text-neutral-800 mt-1 pb-3 border-b border-neutral-400 sm:text-3xl">
          {title}
        </p>
        <div className="flex justify-between mt-3">
          <div className="flex items-center">
            <ClockIcon className="text-neutral-300 w-5" />
            <p className="!leading-normal text-neutral-400 pl-2 sm:text-lg">
              {(minute / 60).toFixed(1)}小時
            </p>
          </div>
          <div className="flex items-center">
            <p className="text-amber-400 sm:text-lg">了解更多</p>
            <ChevronRightIcon className="h-6 text-amber-400 sm:h-8" />
          </div>
        </div>
      </div>
    </Link>
  )
}

Tag.propTypes = {
  children: PropTypes.any,
  isLastItem: PropTypes.bool,
  idx: PropTypes.number,
}

OnlineCourseCard.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  topic: PropTypes.array,
  level: PropTypes.array,
  slug: PropTypes.string,
  minute: PropTypes.number,
  image: PropTypes.object,
  isLast: PropTypes.bool,
  isCreateNewPage: PropTypes.bool,
  isLastItem: PropTypes.bool,
}

export default OnlineCourseCard
